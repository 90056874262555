<template>
    <div>
        <vs-row class="gaps">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
                style="font-size: 17px;"
                ><b>You Can Edit This Number:</b></vs-col
              >
                <vs-col
                    v-if="mobileEdit === false"
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-w="6"
                    style="font-size: 17px;margin-left: -11%;"
                >
                    <b>{{ mobile_number }}</b>
                </vs-col>
                <vs-col
                     v-else-if="mobileEdit === true"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-w="4"
                    
                    style="font-size: 17px;margin-left: 15%;"
                >
                    <vs-input :data-input="this.mobile.id" icon="edit" v-model="editmobile" />
                </vs-col>
                <vs-col
                    v-if="mobileEditbutton === true"
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-w="2"
                    
                    style="font-size: 17px;"
                >
                    <vs-button
                    radius
                    :data-id="this.mobile.id"
                    color="warning"
                    type="border"
                    icon="create"
                    v-on:click="mobileEditfunc(mobile)"
                    ></vs-button>
                </vs-col>
                <vs-col
                    v-if="mobileEditbutton === false"
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-w="2"
                    
                    style="font-size: 17px;"
                >
                    <vs-button
                    radius
                    color="warning"
                    type="border"
                    icon="done_outline"
                    @click="editDesignationAPI"
                    ></vs-button>
                </vs-col>
            </vs-row>
    </div>
</template>
<script>
import axios from "axios";
import constants from "../../constants.json";
import swal from "sweetalert2/src/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// import EventBus from "../components/eventbus.js";
export default {
props: {
    mobile: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      can_id: "",
      PersonName: "",
      EditData: "",
      ContactType: "",
      mobileData: false,
      errorData: false,
      Errordata: "",
      MessageData: "",
      event: "",
      mobileEditbutton: true,
      mobileEdit: false,
      editmobile: "",
      mobile_number: ""
    };
  },
  mounted() {
      console.log("mobile", this.mobile)
      this.mobile_number = this.mobile.phone_number;
  },
  methods: {
    mobileEditfunc(value) {
        this.editmobile = value.phone_number;
        this.mobileEdit = !this.mobileEdit;
        this.mobileEditbutton = !this.mobileEditbutton;
    },
    numberEdit() {
        this.mobileEdit = !this.mobileEdit;
        this.mobileEditbutton = !this.mobileEditbutton;
    },
    editDesignationAPI() {
      swal
        .fire({
          title: "Are you sure?",
          text: `You sure you want to edit this number ${this.mobile.phone_number} to ${
            this.editmobile
          }`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
        if (result.value) {
            let params = {
              edited_number: this.editmobile,
              mobile_id: this.mobile.id,
            };
            console.log(params);
            axios
              .post(`${constants.SERVER_API}editMobile`, params,  {
                headers: {
                  Authorization: `Bearer ${localStorage.userAccessToken}`,
                },
              })
              .then((response) => {
                this.$vs.loading.close();
                console.log(response);
                if (response.data.status === "success") {
                    this.numberEdit();
                    this.mobile_number = response.data.edited_mobile;
                    this.$vs.notify({
                    title: response.data.status,
                    text: response.data.message,
                    color: "success",
                    });
                } else if (response.data.status === "error") {
                    this.numberEdit();
                    this.$vs.notify({
                    title: response.data.status,
                    text: response.data.message,
                    color: "danger",
                    fixed:true,
                    });
                }
              })
              .catch((error) => {
                this.handleError(error);
              });
          }
        });
    },
   
  },
};
</script>