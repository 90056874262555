<template>
  <vx-card actionable class="cardx" title="Edit Mobile Numbers">
    <vs-row class="gaps">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
      ></vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="margin-left: 3%;"
      >
        <vs-input
          label-placeholder="Can-ID"
          v-model="can_id"
          v-on:keypress="isCanId(event)"
        />
        <vs-button
          color="dark"
          type="gradient"
          icon="search"
          @click="search()"
          style="margin-left: 30px;
    margin-top: 17px;"
        ></vs-button>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
      ></vs-col>
    </vs-row>
    <div>
      <vs-row class="gaps">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        ></vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="6"
        >
          <vx-card
            actionable
            class="cardx"
            v-show="mobileData"
            style="margin-top:0px;"
          >
            <vs-row class="gaps">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size: 17px;"
              >
                <b>Person Name:</b>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="6"
                style="font-size:17px;"
              >
                {{ PersonName }}</vs-col
              >
            </vs-row>
            <div v-for="(number, index) in EditData" :key="index">
                <editnumber :mobile="number"></editnumber>
            </div>
          </vx-card>
          <vx-card
            actionable
            class="cardx"
            v-show="errorData"
            style="margin-top:40px; margin-left: -23px"
          >
            <vs-row style="margin:5px">
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
              ></vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="4"
                style="font-size: 17px;"
              >
                <b style="background: red;color: white;border-radius: 7px;"
                  >Error</b
                >
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="4"
              ></vs-col>
            </vs-row>
            <vs-row style="margin-top:25px">
              <vs-col
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
                style="font-size:17px;text-align: center;"
                >{{ MessageData }}</vs-col
              >
            </vs-row>
          </vx-card>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        ></vs-col>
      </vs-row>
    </div>
  </vx-card>
</template>

<script>
import axios from "axios";
import constants from "../../constants.json";
// import swal from "sweetalert2/src/sweetalert2.js";
// import "sweetalert2/src/sweetalert2.scss";
import editnumber from "./editnumber.vue"
// import EventBus from "../components/eventbus.js";
export default {
  data() {
    return {
      can_id: "",
      PersonName: "",
      EditData: "",
      ContactType: "",
      mobileData: false,
      errorData: false,
      Errordata: "",
      MessageData: "",
      event: "",
      mobileEditbutton: true,
      mobileEdit: false,
      editmobile: [],
    };
  },
   components:{
    editnumber
  },
  mounted() {},
  methods: {
    
    // mobileEditfunc(value,) {
    //     console.log("value id", value.id)
    //     this.mobileEdit = !this.mobileEdit;
    //     this.mobileEditbutton = !this.mobileEditbutton;
    //     // if(parseInt(event.currentTarget.getAttribute('data-id')) === value.id) {
    //     //         this.editmobile[index] = value.phone_number;
    //     //         this.mobileEdit = !this.mobileEdit;
    //     //         this.mobileEditbutton = !this.mobileEditbutton;
    //     // }
        
    // },
    isCanId: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode < 31 &&
        (charCode > 48 || charCode < 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    search() {
      let getIdentity = {
        identity: this.can_id,
      };
      console.log(getIdentity);
      //change to cm back
      let url = `${constants.SERVER_API}getMobilesToEdit`;
      axios
        .get(url, {
          params: getIdentity,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.mobileData = true;
            this.errorData = false;
            this.PersonName = response.data.person_name;
            this.EditData = response.data.mobiles;
            // this.ContactType = response.data.contact_type;
            // this.mobile = "";
          } else if (response.data.status === "error") {
            this.mobileData = false;
            this.errorData = true;
            this.Errordata = response.data.status;
            this.MessageData = response.data.message;
            // this.mobile = "";
          }
          console.log("astdftyas0", response);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
